body {
    scroll-behavior: smooth;

    .center {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    img {
      pointer-events: none;
    }

    .tooltip {
      .tooltip-inner {
        padding: 10px;
        background-color: white;
        color: #333;
        border: 1px solid #685e12;
      }
      .tooltip-arrow {
        &::before {
          border-top-color: #685e12;
        }
      }
  }
    
}